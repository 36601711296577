.TopbarContainer {
  height: 67px;
  background: #26262d;
  display: flex;

  position: fixed;
  left: 300px;
  right: 0;
  top: 0;
  z-index: 1;

  justify-content: space-between;
  align-items: center;
}
.TopbarContainer span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 32px;
}
.TopbarContainer span b {
  color: #fff500;
}

.TopbarContainer img {
  vertical-align: middle;
  width: 24px;
  margin-right: 8px;
}

.TopbarContainer span {
  line-height: 24px;
}
.TopbarContainer span img {
  vertical-align: middle;
}

.TopbarContainer ul {
  display: flex;
  flex-direction: row;
}
.TopbarContainer ul li {
  list-style-type: none;
  display: flex;
  margin-right: 32px;
}
.TopbarContainer ul li a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.TopbarContainer ul li a img {
  vertical-align: middle;
}

.TopbarContainer ul li img {
  vertical-align: middle;
}

.TopbarContainer ul button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: 0;
}
