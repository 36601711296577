.lopp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;
}
.lopp .start-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  height: 600px;
  width: 600px;
  padding: 34px;

  background-image: url("/images/lill/TL.png"), url("/images/lill/TR.png"),
    url("/images/lill/BL.png"), url("/images/lill/BR.png");

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
}

.lopp .start-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.lopp p {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 25px;
}

.lopp h1 {
  font-family: "Great Vibes", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 75px;
  color: red;
  margin-top: 2px;
}

.lopp .button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lopp .button-container button {
  border: 1px dashed #fff;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 10px;

  width: 100%;
  margin: 0px 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}
.lopp .button-container button .start {
  background: #fff;
  color: #000;
  opacity: 1;
  color: #000;
}

.lopp .button-container button:hover {
  opacity: 1;
}

.statistika {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.statistika div {
  display: flex;
  flex-direction: column;
}
.statistika b {
  font-size: 40px;
  margin-bottom: 10px;
}

.statistika span {
  text-transform: uppercase;
}
