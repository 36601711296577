.luku_rida {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-btn {
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.close-btn:hover {
  opacity: 0.8;
}

.luku_rida .lukk_input {
  width: 60px;
  border: 3px solid #fff;
  border-radius: 10px;
  color: #fff;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0 10px;
}
.luku_rida .lukk_input button {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 13px;
  cursor: pointer;
}

.lukk_lukus {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lukk_lukus img {
  width: 150px;
}
.lukk_lukus .lukk_lukkus_text {
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-left: 20px;
  font-weight: 300;
  text-align: left;
  width: 150px;
  text-align: center;
}
.lukk_lukus b {
  font-size: 50px;
  font-weight: 600;
}

.pakk_ava_kahtlusalused ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 20px;
}
.pakk_ava_kahtlusalused ul li {
  list-style-type: none;
  padding: 15px 5px;
  font-family: "Montserrat";
  border: 3px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 100;
  font-size: 13px;
  line-height: 17px;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.pakk_ava_kahtlusalused ul li.aktiivne {
  border-color: #4ec14c;
}
