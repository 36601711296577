.kahtlusalused-section {
  margin-left: 60px;
}
.kahtlusalused-section h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  color: #ffffff;
}
.kahtlusalused-section img {
  margin-right: 8px;
  height: 24px;
  vertical-align: middle;
}

.kahtlusalused-section ul {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}
.kahtlusalused-section ul li {
  list-style-type: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 5px;
  color: #ffffff;
  cursor: pointer;
}
.kahtlusalused-section ul li img {
  margin-right: 8px;
}
