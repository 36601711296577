@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
.menu {
  margin-left: 60px;
  margin-bottom: 32px;
}
.menu ul {
  display: flex;
  flex-direction: column;
}
.menu ul li {
  margin-bottom: 24px;
  list-style-type: none;
}
.menu ul li:last-child {
  margin-bottom: 0;
}

.menu ul li a {
  color: #ffffff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration: none;
}
.menu ul li a:hover {
  font-weight: 500;
}

.menu ul li img {
  margin-right: 8px;
  vertical-align: middle;
  width: 24px;
}

.kahtlusaluse_nupp {
  background: transparent;
  border: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 0px;
  padding: 0px;
  color: #ffffff;
  cursor: pointer;
}
.kahtlusaluse_nupp.hide {
  opacity: 0.5;
}

.kahtlusaluse_nupp:hover {
  font-weight: bold;
}

.kahtlusaluse_nupp img {
  width: 13px;
  height: 13px;
}

.kahtlusalused-section {
  margin-left: 60px;
}
.kahtlusalused-section h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  color: #ffffff;
}
.kahtlusalused-section img {
  margin-right: 8px;
  height: 24px;
  vertical-align: middle;
}

.kahtlusalused-section ul {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}
.kahtlusalused-section ul li {
  list-style-type: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 5px;
  color: #ffffff;
  cursor: pointer;
}
.kahtlusalused-section ul li img {
  margin-right: 8px;
}

.SidebarContainer {
  display: flex;
  width: 300px;
  background: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  z-index: 2;
  min-height: 780px;
  min-width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.SidebarContainer .logo {
  width: 200px;
  margin: 30px auto 50px;
}

.main-container {
  padding: 30px;
  margin-top: 62px;
}
.main-container {
  padding: 30px;
  margin-top: 62px;
}

.TopbarContainer {
  height: 67px;
  background: #26262d;
  display: flex;

  position: fixed;
  left: 300px;
  right: 0;
  top: 0;
  z-index: 1;

  justify-content: space-between;
  align-items: center;
}
.TopbarContainer span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 32px;
}
.TopbarContainer span b {
  color: #fff500;
}

.TopbarContainer img {
  vertical-align: middle;
  width: 24px;
  margin-right: 8px;
}

.TopbarContainer span {
  line-height: 24px;
}
.TopbarContainer span img {
  vertical-align: middle;
}

.TopbarContainer ul {
  display: flex;
  flex-direction: row;
}
.TopbarContainer ul li {
  list-style-type: none;
  display: flex;
  margin-right: 32px;
}
.TopbarContainer ul li a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.TopbarContainer ul li a img {
  vertical-align: middle;
}

.TopbarContainer ul li img {
  vertical-align: middle;
}

.TopbarContainer ul button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.right-container {
  flex: 8 1;
  margin-left: 300px;
  height: 100%;
}

.layout-container {
  display: flex;
  flex-direction: rows;
  min-width: 860px;
}

.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #f3f3f3 0%, #c7c7c7 100%);
}

.loginPage .login-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  height: 470px;
  width: 470px;
  padding: 34px;

  background-image: url("/images/lill/TL.png"), url("/images/lill/TR.png"),
    url("/images/lill/BL.png"), url("/images/lill/BR.png");

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
}

.loginPage .must_logo {
  width: 247px;
  transform: translateY(-35px);
  display: block;
  z-index: 99;
  position: relative;
}

.loginPage .login-container .login-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginPage .login-container .login-inside-container p {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 60px;
}

.loginPage .login-container .login-inside-container h1 {
  font-family: "Great Vibes", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 75px;
  color: #ffffff;
  margin-top: 2px;
  margin-bottom: 30px;
}

.loginPage .login-container .login-inside-container input {
  border: 1px dashed #fff;
  width: 284px;
  background: transparent;
  padding: 10px 0;
  text-align: center;
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  margin-bottom: 15px;
}

.loginPage .login-container .login-inside-container button {
  width: 284px;
  background: #fff;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 10px 0px;
  letter-spacing: 1px;
  border: 0;
  cursor: pointer;
  color: #000000;
}

.loginPage .yleminelill {
  position: absolute;
  top: 0;
  right: 0;
}

.loginPage .aluminelill {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 589px;
}

.loginPage .vasak_kasi {
  position: absolute;
  top: 40px;
  left: 80px;
}
.loginPage .vasak_kasi img {
  height: 400px;
}

.loginPage .parem_kasi {
  position: absolute;
  bottom: 20px;
  right: 100px;
}
.loginPage .parem_kasi img {
  height: 400px;
}

.loginPage .keskmine {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.startPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.startPage .start-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  height: 470px;
  width: 470px;
  padding: 34px;
  background-image: url("/images/lill/TL.png"), url("/images/lill/TR.png"),
    url("/images/lill/BL.png"), url("/images/lill/BR.png");
  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
}

.startPage .start-container .start-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.startPage .start-container .start-inside-container p {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 25px;
}

.startPage .start-container .start-inside-container h1 {
  font-family: "Great Vibes", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 75px;
  color: red;
  margin-top: 2px;
}

.startPage .start-container .start-inside-container .button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.startPage .start-container .start-inside-container .button-container button {
  border: 1px dashed #fff;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 10px;

  width: 100%;
  margin: 0px 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}

.startPage
  .start-container
  .start-inside-container
  .button-container
  button:hover {
  opacity: 1;
}

.startPage
  .start-container
  .start-inside-container
  .button-container
  button.start {
  background: #fff;
  color: #000;
  opacity: 1;
  color: #000;
}

.startPage
  .start-container
  .start-inside-container
  .button-container
  button.start:hover {
  opacity: 0.95;
}

/*.start-page {

}
.start-page 

.start-page 
.start-page .start-inside-container 
.start-page .start-inside-container 

.start-page .start-inside-container 
.start-page .start-inside-container .button-container 
.start-page .start-inside-container .button-container

.start-page .start-inside-container .button-container
*/

.pakkPage .kast ul li {
  list-style-type: none;
  line-height: 35px;

  transition: all 0.1s ease-in-out;
}

.pakkPage .kast ul li:hover {
  font-weight: bold;
  padding-left: 5px;
}

.pakkPage .kast.bol b {
  font-weight: 500;
}

.pakkPage .failid {
  margin-top: 20px;
}

.pakkPage .kastButtons,
.eelluguPage .kastButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}
.pakkPage .kastButtons .vihjeButtonKast {
  display: flex;
  flex-direction: row;
}

.pakkPage .kastButtons button,
.eelluguPage .kastButtons button {
  background: #000000;
  color: #fff;
  cursor: pointer;
  width: 248px;
  height: 47px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 8px;
  border: 0px;
}

.pakkPage .kastButtons button:hover,
.eelluguPage .kastButtons button:hover {
  background: #252525 !important;
}

.pakkPage .kastButtons button:disabled {
  opacity: 0.3;
}

.pakkPage .kastButtons .vihjeButtonKast img,
.eelluguPage .kastButtons img {
  vertical-align: middle;
}

.pakkPage .kastButtons .vihjedRow {
  margin-top: 8px;
  margin-left: 5px;
}
.pakkPage .kastButtons .vihjedRow div {
  background: #fff;
  display: flex;
  padding: 0 15px;
  border-radius: 3px;
  height: 47px;
}
.pakkPage .kastButtons .vihjedRow img {
  margin-right: 16px;
  vertical-align: middle;
}
.pakkPage .kastButtons .vihjedRow img.kasutatud {
  opacity: 0.2;
}

.pakkPage .kastButtons .vihjedRow img:last-child {
  margin-right: 0;
}

.pakkPage .vihjed ul li {
  list-style-type: none;

  margin-bottom: 8px;
}
.pakkPage .vihjed ul li .vihje_kast {
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  padding: 8px;

  background-image: url("/images/vihje_logo.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 30px;
}
.pakkPage .vihjed ul li img {
  width: 24px;
  vertical-align: middle;
}

.pakkPage .vihjed ul li span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
  color: #000000;
}

.pakkPage .vihjed ul li p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-left: 10px;
  color: #000000;
}

.pakkPage .lukk {
  vertical-align: middle;
}

.pakkPage .lukk_lukus {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pakkPage .lukk_lukus img {
  width: 100px;
}
.pakkPage .lukk_lukus .lukk_lukkus_text {
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 20px;
  font-weight: 300;
  text-align: left;
}
.pakkPage .lukk_lukus b {
  font-size: 33px;
  font-weight: 600;
}

.pakkPage .kastButtons button.lyhemnupp {
  margin-right: 5px;
  width: 50px !important;
}

.VotaYhendustPage a {
  color: #000;
}

.VotaYhendustPage svg {
  cursor: pointer;
  vertical-align: middle;
}

.VotaYhendustPage .yhendust {
  margin-bottom: 15px;
  line-height: 28px;
}

.VotaYhendustPage input {
  width: 100%;
  height: 24px;
  border: 1px solid lightgray;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.VotaYhendustPage textarea {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  resize: none;
  height: 150px;
  padding: 15px 10px;
}

.VotaYhendustPage .buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.VotaYhendustPage .buttonContainer button {
  padding: 10px 15px;
  background: #4ec14c;
  border: 0px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
.VotaYhendustPage .buttonContainer button:active {
  background: #43a141;
}

*  {
  box-sizing: border-box;
}
.asitoendi_item_container {
  width: 250px;
  height: 360px;
  background: #fff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  transition: 0.3s;
}
.asitoendi_item_container.locked {
  opacity: 0.9;
}

.asitoendi_item_container:hover {
  opacity: 1;
}

.asitoendi_item_container .ylemine {
  display: flex;
  flex: 6 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.asitoendi_item_container .ylemine img {
  width: 64px;
  height: 118px;
  margin-bottom: 32px;
}
.asitoendi_item_container .ylemine img.locked {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 12px;
  height: auto;
}

.asitoendi_item_container h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.asitoendi_item_container p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.asitoendi_item_container .alumine {
  background: #26262d;
  padding: 15px 30px;
}
.asitoendi_item_container .alumine ul {
  display: flex;
  justify-content: space-evenly;
}
.asitoendi_item_container .alumine ul li {
  margin: 0;
  margin-bottom: 15px;
  list-style-type: none;
  color: rgba(255, 255, 255, 0.4);
  font-family: Montserrat;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
}
.asitoendi_item_container .alumine ul li img {
  width: 12px;
  vertical-align: middle;
  margin-right: 8px;
}

.asitoendi_item_container .alumine button {
  border: 1px dashed #fff;
  background: transparent;
  color: #fff;
  width: 100%;
  padding: 12px 0px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 3px;
  cursor: pointer;
}
.asitoendi_item_container .alumine button:hover {
  background: #fff;
  color: #26262d;
  border: 1px dashed #26262d;
}

.Asitoendi_list_container li {
  display: inline-block;
  margin: 20px;
}

.asitoendid h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 2px;
  color: #000000;
}

/*** LOGIN PAGE ***/

.modal-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(38, 38, 45, 0.93);

  background-image: url("/images/main_bg/keskmine.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1024px 751px, auto;
  background-attachment: fixed;

  color: "#fff";
  font-size: "40px";
  z-index: 2;
}
.modal-page .modal-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);

  padding: 34px;

  background-image: url("/images/lill/TL.png"), url("/images/lill/BR.png");

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
  position: relative;
}
.modal-page .close-btn {
  font-size: 40px;
  color: #b30303;
  font-weight: 500;
  background: transparent;
  border: 0px;
  position: absolute;
  top: 30px;
  right: 50px;
}

.modal-page .modal-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  padding: 45px 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-page p {
  font-family: "Montserrat", sans-serif;
  color: #fff;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 30px;
}

.modal-page h1 {
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  letter-spacing: 3.5px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-page .lukk {
  color: #fff;
  width: 30px;
  margin-top: 0px;
}

.modal-page input {
  border: 1px dashed #fff;
  width: 284px;
  background: transparent;
  padding: 10px 0px;
  text-align: center;
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  margin-bottom: 15px;
}
.modal-page .button-container {
  width: 100%;
}
.modal-page .button-container button {
  background: #fff;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 10px;

  width: 100%;

  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
  border: 0px;
}
.modal-page .button-container :active {
  background: rgba(255, 255, 255, 0.8);
}

.modal-page span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin-top: 15px;
  color: #ffffff;
}

.modal-close- {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.luku_rida {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-btn {
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.close-btn:hover {
  opacity: 0.8;
}

.luku_rida .lukk_input {
  width: 60px;
  border: 3px solid #fff;
  border-radius: 10px;
  color: #fff;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0 10px;
}
.luku_rida .lukk_input button {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 13px;
  cursor: pointer;
}

.lukk_lukus {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lukk_lukus img {
  width: 150px;
}
.lukk_lukus .lukk_lukkus_text {
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding-left: 20px;
  font-weight: 300;
  text-align: left;
  width: 150px;
  text-align: center;
}
.lukk_lukus b {
  font-size: 50px;
  font-weight: 600;
}

.pakk_ava_kahtlusalused ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 20px;
}
.pakk_ava_kahtlusalused ul li {
  list-style-type: none;
  padding: 15px 5px;
  font-family: "Montserrat";
  border: 3px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 100;
  font-size: 13px;
  line-height: 17px;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.pakk_ava_kahtlusalused ul li.aktiivne {
  border-color: #4ec14c;
}

.lopp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;
}
.lopp .start-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  height: 600px;
  width: 600px;
  padding: 34px;

  background-image: url("/images/lill/TL.png"), url("/images/lill/TR.png"),
    url("/images/lill/BL.png"), url("/images/lill/BR.png");

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
}

.lopp .start-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.lopp p {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 25px;
}

.lopp h1 {
  font-family: "Great Vibes", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 75px;
  color: red;
  margin-top: 2px;
}

.lopp .button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lopp .button-container button {
  border: 1px dashed #fff;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 10px;

  width: 100%;
  margin: 0px 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}
.lopp .button-container button .start {
  background: #fff;
  color: #000;
  opacity: 1;
  color: #000;
}

.lopp .button-container button:hover {
  opacity: 1;
}

.statistika {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}
.statistika div {
  display: flex;
  flex-direction: column;
}
.statistika b {
  font-size: 40px;
  margin-bottom: 10px;
}

.statistika span {
  text-transform: uppercase;
}

.konto-on-lukus-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;
}
.konto-on-lukus-page .start-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  height: 470px;
  width: 470px;
  padding: 34px;

  background-image: url('/images/lill/BL.png'), url('/images/lill/TR.png'),
    url('/images/lill/BL.png'), url('/images/lill/BR.png');

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
}

.konto-on-lukus-page .start-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.konto-on-lukus-page p {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 25px;
}

.konto-on-lukus-page h1 {
  font-family: 'Great Vibes', cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 75px;
  color: red;
  margin-top: 2px;
}

.konto-on-lukus-page .button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.konto-on-lukus-page .button-container button {
  border: 1px dashed #fff;
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 10px;

  width: 100%;
  margin: 0px 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  opacity: 0.5;
}
.konto-on-lukus-page .button-container button.start {
  background: #fff;
  color: #000;
  opacity: 1;
  color: #000;
}

.konto-on-lukus-page .button-container button.start:hover {
  opacity: 1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

input:focus,
button:focus {
  outline: 0;
}

html {
  background-image: url("/images/main_bg/ylemine_kasi.png"),
    url("/images/main_bg/keskmine.png"), url("/images/main_bg/alumine_kasi.png"),
    linear-gradient(180deg, #f3f3f3 0%, #c7c7c7 100%);
  background-position: top center, center, bottom right;
  background-repeat: no-repeat;
  background-size: 422px 244px, 1024px 751px, 125px 234px, auto;
  background-attachment: fixed;
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 2px;
  color: #000000;
  margin-bottom: 7px;
}

.subtitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 3px;
  color: #000000;
  margin-bottom: 40px;
}

.kast {
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #000;
  padding: 40px 50px;
}

/*

@media (max-width: 768px) {
  body {
    display: none;
  }
}



.kkk .yhendust {
  margin-bottom: 15px;
  line-height: 28px;
}
.kkk a {
  color: #000;
}
.kkk svg {
  cursor: pointer;
  vertical-align: middle;
}
*/

textarea:focus,
input:focus {
  outline: none;
}

#mobiil {
	display: none;
}


@media (max-width: 870px) {
  #root {
    display: none;
  }
  #mobiil {
	 display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 30px;
    color: rebeccapurple;

    margin: 30px;
  }
  #mobiil h2 {
	  background: #000;
    padding: 20px 50px;
    color: #fff;
    border-radius: 10px;
    font-weight: 100;

    text-align: center;
  }
}
