.loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #f3f3f3 0%, #c7c7c7 100%);
}

.loginPage .login-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  height: 470px;
  width: 470px;
  padding: 34px;

  background-image: url("/images/lill/TL.png"), url("/images/lill/TR.png"),
    url("/images/lill/BL.png"), url("/images/lill/BR.png");

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) 10px, 10px calc(100% - 10px),
    calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
}

.loginPage .must_logo {
  width: 247px;
  transform: translateY(-35px);
  display: block;
  z-index: 99;
  position: relative;
}

.loginPage .login-container .login-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginPage .login-container .login-inside-container p {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 60px;
}

.loginPage .login-container .login-inside-container h1 {
  font-family: "Great Vibes", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 75px;
  color: #ffffff;
  margin-top: 2px;
  margin-bottom: 30px;
}

.loginPage .login-container .login-inside-container input {
  border: 1px dashed #fff;
  width: 284px;
  background: transparent;
  padding: 10px 0;
  text-align: center;
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  margin-bottom: 15px;
}

.loginPage .login-container .login-inside-container button {
  width: 284px;
  background: #fff;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 10px 0px;
  letter-spacing: 1px;
  border: 0;
  cursor: pointer;
  color: #000000;
}

.loginPage .yleminelill {
  position: absolute;
  top: 0;
  right: 0;
}

.loginPage .aluminelill {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 589px;
}

.loginPage .vasak_kasi {
  position: absolute;
  top: 40px;
  left: 80px;
}
.loginPage .vasak_kasi img {
  height: 400px;
}

.loginPage .parem_kasi {
  position: absolute;
  bottom: 20px;
  right: 100px;
}
.loginPage .parem_kasi img {
  height: 400px;
}

.loginPage .keskmine {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
