.pakkPage .kast ul li {
  list-style-type: none;
  line-height: 35px;

  transition: all 0.1s ease-in-out;
}

.pakkPage .kast ul li:hover {
  font-weight: bold;
  padding-left: 5px;
}

.pakkPage .kast.bol b {
  font-weight: 500;
}

.pakkPage .failid {
  margin-top: 20px;
}

.pakkPage .kastButtons,
.eelluguPage .kastButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}
.pakkPage .kastButtons .vihjeButtonKast {
  display: flex;
  flex-direction: row;
}

.pakkPage .kastButtons button,
.eelluguPage .kastButtons button {
  background: #000000;
  color: #fff;
  cursor: pointer;
  width: 248px;
  height: 47px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 8px;
  border: 0px;
}

.pakkPage .kastButtons button:hover,
.eelluguPage .kastButtons button:hover {
  background: #252525 !important;
}

.pakkPage .kastButtons button:disabled {
  opacity: 0.3;
}

.pakkPage .kastButtons .vihjeButtonKast img,
.eelluguPage .kastButtons img {
  vertical-align: middle;
}

.pakkPage .kastButtons .vihjedRow {
  margin-top: 8px;
  margin-left: 5px;
}
.pakkPage .kastButtons .vihjedRow div {
  background: #fff;
  display: flex;
  padding: 0 15px;
  border-radius: 3px;
  height: 47px;
}
.pakkPage .kastButtons .vihjedRow img {
  margin-right: 16px;
  vertical-align: middle;
}
.pakkPage .kastButtons .vihjedRow img.kasutatud {
  opacity: 0.2;
}

.pakkPage .kastButtons .vihjedRow img:last-child {
  margin-right: 0;
}

.pakkPage .vihjed ul li {
  list-style-type: none;

  margin-bottom: 8px;
}
.pakkPage .vihjed ul li .vihje_kast {
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  padding: 8px;

  background-image: url("/images/vihje_logo.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 30px;
}
.pakkPage .vihjed ul li img {
  width: 24px;
  vertical-align: middle;
}

.pakkPage .vihjed ul li span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
  color: #000000;
}

.pakkPage .vihjed ul li p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-left: 10px;
  color: #000000;
}

.pakkPage .lukk {
  vertical-align: middle;
}

.pakkPage .lukk_lukus {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pakkPage .lukk_lukus img {
  width: 100px;
}
.pakkPage .lukk_lukus .lukk_lukkus_text {
  color: #fff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 20px;
  font-weight: 300;
  text-align: left;
}
.pakkPage .lukk_lukus b {
  font-size: 33px;
  font-weight: 600;
}

.pakkPage .kastButtons button.lyhemnupp {
  margin-right: 5px;
  width: 50px !important;
}
