@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

input:focus,
button:focus {
  outline: 0;
}

html {
  background-image: url("/images/main_bg/ylemine_kasi.png"),
    url("/images/main_bg/keskmine.png"), url("/images/main_bg/alumine_kasi.png"),
    linear-gradient(180deg, #f3f3f3 0%, #c7c7c7 100%);
  background-position: top center, center, bottom right;
  background-repeat: no-repeat;
  background-size: 422px 244px, 1024px 751px, 125px 234px, auto;
  background-attachment: fixed;
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 2px;
  color: #000000;
  margin-bottom: 7px;
}

.subtitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 3px;
  color: #000000;
  margin-bottom: 40px;
}

.kast {
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #000;
  padding: 40px 50px;
}

/*

@media (max-width: 768px) {
  body {
    display: none;
  }
}



.kkk .yhendust {
  margin-bottom: 15px;
  line-height: 28px;
}
.kkk a {
  color: #000;
}
.kkk svg {
  cursor: pointer;
  vertical-align: middle;
}
*/

textarea:focus,
input:focus {
  outline: none;
}

#mobiil {
	display: none;
}


@media (max-width: 870px) {
  #root {
    display: none;
  }
  #mobiil {
	 display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 30px;
    color: rebeccapurple;

    margin: 30px;
  }
  #mobiil h2 {
	  background: #000;
    padding: 20px 50px;
    color: #fff;
    border-radius: 10px;
    font-weight: 100;

    text-align: center;
  }
}