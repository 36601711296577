.kahtlusaluse_nupp {
  background: transparent;
  border: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 0px;
  padding: 0px;
  color: #ffffff;
  cursor: pointer;
}
.kahtlusaluse_nupp.hide {
  opacity: 0.5;
}

.kahtlusaluse_nupp:hover {
  font-weight: bold;
}

.kahtlusaluse_nupp img {
  width: 13px;
  height: 13px;
}
