.VotaYhendustPage a {
  color: #000;
}

.VotaYhendustPage svg {
  cursor: pointer;
  vertical-align: middle;
}

.VotaYhendustPage .yhendust {
  margin-bottom: 15px;
  line-height: 28px;
}

.VotaYhendustPage input {
  width: 100%;
  height: 24px;
  border: 1px solid lightgray;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.VotaYhendustPage textarea {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid lightgray;
  resize: none;
  height: 150px;
  padding: 15px 10px;
}

.VotaYhendustPage .buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.VotaYhendustPage .buttonContainer button {
  padding: 10px 15px;
  background: #4ec14c;
  border: 0px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}
.VotaYhendustPage .buttonContainer button:active {
  background: #43a141;
}
