.SidebarContainer {
  display: flex;
  width: 300px;
  background: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  z-index: 2;
  min-height: 780px;
  min-width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.SidebarContainer .logo {
  width: 200px;
  margin: 30px auto 50px;
}
