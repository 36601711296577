.menu {
  margin-left: 60px;
  margin-bottom: 32px;
}
.menu ul {
  display: flex;
  flex-direction: column;
}
.menu ul li {
  margin-bottom: 24px;
  list-style-type: none;
}
.menu ul li:last-child {
  margin-bottom: 0;
}

.menu ul li a {
  color: #ffffff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration: none;
}
.menu ul li a:hover {
  font-weight: 500;
}

.menu ul li img {
  margin-right: 8px;
  vertical-align: middle;
  width: 24px;
}
