*  {
  box-sizing: border-box;
}
.asitoendi_item_container {
  width: 250px;
  height: 360px;
  background: #fff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  transition: 0.3s;
}
.asitoendi_item_container.locked {
  opacity: 0.9;
}

.asitoendi_item_container:hover {
  opacity: 1;
}

.asitoendi_item_container .ylemine {
  display: flex;
  flex: 6;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.asitoendi_item_container .ylemine img {
  width: 64px;
  height: 118px;
  margin-bottom: 32px;
}
.asitoendi_item_container .ylemine img.locked {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 12px;
  height: auto;
}

.asitoendi_item_container h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.asitoendi_item_container p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.asitoendi_item_container .alumine {
  background: #26262d;
  padding: 15px 30px;
}
.asitoendi_item_container .alumine ul {
  display: flex;
  justify-content: space-evenly;
}
.asitoendi_item_container .alumine ul li {
  margin: 0;
  margin-bottom: 15px;
  list-style-type: none;
  color: rgba(255, 255, 255, 0.4);
  font-family: Montserrat;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
}
.asitoendi_item_container .alumine ul li img {
  width: 12px;
  vertical-align: middle;
  margin-right: 8px;
}

.asitoendi_item_container .alumine button {
  border: 1px dashed #fff;
  background: transparent;
  color: #fff;
  width: 100%;
  padding: 12px 0px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 3px;
  cursor: pointer;
}
.asitoendi_item_container .alumine button:hover {
  background: #fff;
  color: #26262d;
  border: 1px dashed #26262d;
}
