/*** LOGIN PAGE ***/

.modal-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  min-height: 700px;
  position: relative;
  overflow: hidden;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(38, 38, 45, 0.93);

  background-image: url("/images/main_bg/keskmine.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1024px 751px, auto;
  background-attachment: fixed;

  color: "#fff";
  font-size: "40px";
  z-index: 2;
}
.modal-page .modal-container {
  background-color: #26262d;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);

  padding: 34px;

  background-image: url("/images/lill/TL.png"), url("/images/lill/BR.png");

  background-repeat: no-repeat;
  background-size: 96px 90px;
  background-position: 10px 10px, calc(100% - 10px) calc(100% - 10px);
  z-index: 99;
  position: relative;
}
.modal-page .close-btn {
  font-size: 40px;
  color: #b30303;
  font-weight: 500;
  background: transparent;
  border: 0px;
  position: absolute;
  top: 30px;
  right: 50px;
}

.modal-page .modal-inside-container {
  border: 1px dashed #fff;
  height: 100%;
  z-index: 2;
  padding: 45px 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-page p {
  font-family: "Montserrat", sans-serif;
  color: #fff;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 30px;
}

.modal-page h1 {
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  letter-spacing: 3.5px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-page .lukk {
  color: #fff;
  width: 30px;
  margin-top: 0px;
}

.modal-page input {
  border: 1px dashed #fff;
  width: 284px;
  background: transparent;
  padding: 10px 0px;
  text-align: center;
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  margin-bottom: 15px;
}
.modal-page .button-container {
  width: 100%;
}
.modal-page .button-container button {
  background: #fff;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 10px;

  width: 100%;

  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
  border: 0px;
}
.modal-page .button-container :active {
  background: rgba(255, 255, 255, 0.8);
}

.modal-page span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  margin-top: 15px;
  color: #ffffff;
}

.modal-close- {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
